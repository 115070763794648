export interface Error {
  status: number
  name: string
  message: string
  errorCode: string
}

type ErrorData = {
  [key: string]: {
    errorCode: string
    message: string
  }
}

export const errorData: ErrorData = {
  CANNOT_UPDATE_CLOSINGDAY_HAVE_RESERVATION: {
    errorCode: 'CANNOT_UPDATE_CLOSINGDAY_HAVE_RESERVATION',
    message: '해당 날짜에 예약이 존재하여 수정이 불가능합니다',
  },
  CANNOT_SAME_START_END_TIME: {
    errorCode: 'CANNOT_SAME_START_END_TIME',
    message: '시작 시간과 종료 시간은 동일하게 설정할 수 없습니다.',
  },
  HAVE_RESERVATIONS_IN_BREAK_TIME: {
    errorCode: 'HAVE_RESERVATIONS_IN_BREAK_TIME',
    message: '해당 시간에 예약이 존재하여 수정이 불가능합니다.',
  },
  HAVE_RESERVATIONS_IN_WAITING: {
    errorCode: 'HAVE_RESERVATIONS_IN_WAITING',
    message: '현재 대기중인 팀이 존재하여 변경이 불가능합니다.',
  },
  HAVE_RESERVATIONS_OUT_OF_OPEN_TIME: {
    errorCode: 'HAVE_RESERVATIONS_OUT_OF_OPEN_TIME',
    message: '해당 시간에 예약이 존재하여 수정이 불가능합니다.',
  },
  TIME_SALE_SHOULD_BE_INCLUDED_IN_ORDER_TIME: {
    errorCode: 'TIME_SALE_SHOULD_BE_INCLUDED_IN_ORDER_TIME',
    message: '영업시간은 타임세일 시간을 포함해야 합니다.',
  },
  REMOTE_WAITING_TIME_NOT_INCLUDED_OPEN_TIME: {
    errorCode: 'REMOTE_WAITING_TIME_NOT_INCLUDED_OPEN_TIME',
    message: '원격줄서기 접수 시간은 영업시간 내에서 설정해주세요.',
  },
  RESERVABLE_TIME_MUST_7_LENGTH: {
    errorCode: 'RESERVABLE_TIME_MUST_7_LENGTH',
    message: '운영시간 수정은 7개 요일을 모두 요청해야 합니다.',
  },
  MUST_HAVE_OPEN_TIME: {
    errorCode: 'MUST_HAVE_OPEN_TIME',
    message: '운영시간 설정값이 필수여야 합니다.',
  },
  RESERVABLE_TIME_RANGE_IS_OVERLAPPED: {
    errorCode: 'RESERVABLE_TIME_RANGE_IS_OVERLAPPED',
    message: '이미 등록된 시간과 중복되지 않게 설정해주세요.',
  },
  BREAK_TIME_NOT_INCLUDED_OPEN_TIME: {
    errorCode: 'BREAK_TIME_NOT_INCLUDED_OPEN_TIME',
    message: '브레이크 타임은 영업시간 내에서 설정해주세요.',
  },
  CANNOT_UPDATE_BLOCK_SESSION_HAS_RESERVATION: {
    errorCode: 'CANNOT_UPDATE_BLOCK_SESSION_HAS_RESERVATION',
    message: '해당 날짜에 예약이 존재하여 수정이 불가능합니다.',
  },
  RESERVABLE_TIME_NOT_INCLUDED_OPEN_TIME: {
    errorCode: 'RESERVABLE_TIME_NOT_INCLUDED_OPEN_TIME',
    message: '영업시간은 예약 가능 시간을 포함해야 합니다',
  },
  NOT_FOUND_USER: {
    errorCode: 'NOT_FOUND_USER',
    message: '유저를 찾을 수 없습니다.',
  },
  WRONG_PASSWORD: {
    errorCode: 'WRONG_PASSWORD',
    message: '비밀번호가 일치하지 않습니다. (영문자/숫자/특수문자 혼합)',
  },
  CANNOT_LOGIN_MANAGER_ROLE: {
    errorCode: 'CANNOT_LOGIN_MANAGER_ROLE',
    message: '사장님 계정은 로그인할 수 없습니다.',
  },
  CANNOT_LOGIN_STAFF_ROLE: {
    errorCode: 'CANNOT_LOGIN_STAFF_ROLE',
    message: 'STAFF 계정은 로그인할 수 없습니다.',
  },
  PENDING_STATUS_USER: {
    errorCode: 'PENDING_STATUS_USER',
    message: '승인 대기 상태의 계정입니다.',
  },
  RETURN_STATUS_USER: {
    errorCode: 'RETURN_STATUS_USER',
    message: '승인 반려 상태의 계정입니다.',
  },
  DELETE_STATUS_USER: {
    errorCode: 'DELETE_STATUS_USER',
    message: '비활성 상태의 계정입니다.',
  },
  PAUSE_STATUS_USER: {
    errorCode: 'PAUSE_STATUS_USER',
    message: '일시 정지 상태의 계정입니다.',
  },
  UNKNOWN_ERROR: {
    errorCode: 'UNKNOWN_ERROR',
    message: '알 수 없는 오류가 발생했습니다.',
  },
  TOKEN_EXPIRED: {
    errorCode: 'TOKEN_EXPIRED',
    message: '토큰이 만료되었습니다.',
  },
  INVALID_JWT_TOKEN: {
    errorCode: 'INVALID_JWT_TOKEN',
    message: '유효하지 않은 토큰입니다.',
  },
  AUTHORIZATION_MISSING: {
    errorCode: 'AUTHORIZATION_MISSING',
    message: 'authorization 값을 찾을 수 없습니다.',
  },
  CANNOT_ACCESS_MANAGER: {
    errorCode: 'CANNOT_ACCESS_MANAGER',
    message: '사장님 계정은 접근할 수 없습니다.',
  },
  CANNOT_ACCESS_STAFF: {
    errorCode: 'CANNOT_ACCESS_STAFF',
    message: 'STAFF 계정은 접근할 수 없습니다.',
  },
  INVALID_IP: {
    errorCode: 'INVALID_IP',
    message: '허용된 IP로 접속하거나, VPN 연결이 필요한 작업입니다.',
  },
  ALREADY_EXIST_USER_EMAIL: {
    errorCode: 'ALREADY_EXIST_USER_EMAIL',
    message: '이미 가입된 이메일 입니다.',
  },
  INVALID_EMAIL_TYPE: {
    errorCode: 'INVALID_EMAIL_TYPE',
    message: '이메일 형식으로 입력해 주세요.',
  },
  NOT_MATCH_VERIFY_NUMBER: {
    errorCode: 'NOT_MATCH_VERIFY_NUMBER',
    message: '인증번호가 일치하지 않습니다.',
  },
  TIME_OVER_VERIFY_NUMBER: {
    errorCode: 'TIME_OVER_VERIFY_NUMBER',
    // message: '인증 시간이 지났습니다.',
    message: '인증번호 유효 시간이 만료되었습니다.',
  },
  // 참고: 아래 에러는 여러 API에서 사용되고 있습니다. (예시. 회원가입 이메일 인증)
  // 따라서 API에 따라 개별적으로 에러 메세지를 정의해야 할 수 있습니다!
  // TODO: 추후 수정이 필요하지 않을까 싶습니다.
  DATA_TYPE_EXCEPTION: {
    errorCode: 'DATA_TYPE_EXCEPTION',
    message: '잘못된 데이터 타입입니다. bankCD 값을 확인해주세요.',
  },
  ALREADY_EXISTS_RESTAURANT: {
    errorCode: 'ALREADY_EXISTS_RESTAURANT',
    message: '이미 계약 옵션에 등록된 매장입니다.',
  },
  IDX_TYPE_EXCEPTION: {
    errorCode: 'IDX_TYPE_EXCEPTION',
    message: 'idx값이 number 타입이어야 합니다.',
  },
  NOT_FOUND_CONTRACT_OPTION: {
    errorCode: 'NOT_FOUND_CONTRACT_OPTION',
    message: '해당 계약 옵션을 찾을 수 없습니다.',
  },
  TERMINATED_CONTRACT_OPTION: {
    errorCode: 'TERMINATED_CONTRACT_OPTION',
    message: '해당 계약은 이미 해지되었습니다.',
  },
  NOT_FOUND_AMOUNT_ADJUSTMENT: {
    errorCode: 'NOT_FOUND_AMOUNT_ADJUSTMENT',
    message: '해당 조정 금액 정보를 찾을 수 없습니다.',
  },
  NOT_FOUND_CYCLE_SETTLE_ACCOUNT: {
    errorCode: 'NOT_FOUND_CYCLE_SETTLE_ACCOUNT',
    message: '주기 정산 데이터를 찾을 수 없습니다.',
  },
  NOT_FOUND_MOBILE_USER: {
    errorCode: 'NOT_FOUND_MOBILE_USER',
    message: '해당 사용자를 찾을 수 없습니다.',
  },
  NOT_FOUND_CORPORATION: {
    errorCode: 'NOT_FOUND_CORPORATION',
    message: '해당 법인 정보를 찾을 수 없습니다.',
  },
  ALREADY_EXISTS_RESTAURANT_IDX: {
    errorCode: 'ALREADY_EXISTS_RESTAURANT_IDX',
    message: '해당 restaurantIdx를 가진 법인이 이미 존재합니다.',
  },
  NOT_FOUND_RESTAURANT: {
    errorCode: 'NOT_FOUND_RESTAURANT',
    message: '해당 매장을 찾을 수 없습니다.',
  },
  MUST_BE_NUMBER_TYPE: {
    errorCode: 'MUST_BE_NUMBER_TYPE',
    message: 'restaurantIdx 값은 숫자 형식이어야 합니다.',
  },
  HAS_WAITING_EVENT: {
    errorCode: 'HAS_WAITING_EVENT',
    message: '해당 매장에 대기 건이 있습니다.',
  },
  REQUIRED_EXCEPTION: {
    errorCode: 'REQUIRED_EXCEPTION',
    message: 'isDeleted 값은 필수입니다.',
  },
  OBJECT_ID_TYPE_EXCEPTION: {
    errorCode: 'OBJECT_ID_TYPE_EXCEPTION',
    message: '_id 값은 ObjectId 형식이어야 합니다.',
  },
  CURATION_NOT_FOUND: {
    errorCode: 'CURATION_NOT_FOUND',
    message: '해당 큐레이션을 찾을 수 없습니다.',
  },
  NO_DATA_PROVIDED: {
    errorCode: 'NO_DATA_PROVIDED',
    message: '최소 하나 이상의 데이터가 필요합니다.',
  },
  BANNER_NOT_FOUND: {
    errorCode: 'BANNER_NOT_FOUND',
    message: '해당 배너를 찾을 수 없습니다.',
  },
  NOT_MATCH_CI: {
    errorCode: 'NOT_MATCH_CI',
    message: 'ci 값이 일치하지 않습니다.',
  },
  ALREADY_EXIST_PARTNER_COMPANY_TABLE: {
    errorCode: 'ALREADY_EXIST_PARTNER_COMPANY_TABLE',
    message: '해당 테이블은 이미 매핑된 정보가 있습니다.',
  },
  NOT_FOUND_RESTAURANT_TABLE: {
    errorCode: 'NOT_FOUND_RESTAURANT_TABLE',
    message: '매핑 하고자 하는 테이블 정보를 찾을 수 없습니다.',
  },
  NULL_IS_NOT_ALLOWED_IN_PARTNER_COMPANY_ENDPOINT: {
    errorCode: 'NULL_IS_NOT_ALLOWED_IN_PARTNER_COMPANY_ENDPOINT',
    message: '연동 업체의 End Point 항목이 설정되지 않았습니다.',
  },
  NOT_FOUND_PARTNER_COMPANY_RAW_TABLE_DATA: {
    errorCode: 'NOT_FOUND_PARTNER_COMPANY_RAW_TABLE_DATA',
    message: '연동 업체의 테이블 정보를 찾을 수 없습니다.',
  },
  NO_TABLE_CODE_IN_THE_PARTNER_COMPANY: {
    errorCode: 'NO_TABLE_CODE_IN_THE_PARTNER_COMPANY',
    message: '연동 업체에 해당 테이블 코드의 데이터가 없습니다.',
  },
  INVALID_VERIFY_NUMBER_LENGTH: {
    errorCode: 'INVALID_VERIFY_NUMBER_LENGTH',
    message: '인증 번호는 6자리여야 합니다.',
  },
  ALREADY_EXISTS_RESTAURANT_NAME: {
    errorCode: 'ALREADY_EXISTS_RESTAURANT_NAME',
    message: '동일한 매장명이 존재합니다.',
  },
  FAILED_CREATE_RESTAURANT: {
    errorCode: 'FAILED_CREATE_RESTAURANT',
    message: '매장 생성에 실패했습니다.',
  },
  TIME_OVER_CHANGE_PASSWORD: {
    errorCode: 'TIME_OVER_CHANGE_PASSWORD',
    message:
      '비밀번호 재설정 유효시간이 초과되었습니다. 비밀번호 재설정 링크를 다시 발송해주세요.',
  },
  NOT_FOUND_NUMBER_IN_VERIFY_LIST: {
    errorCode: 'NOT_FOUND_NUMBER_IN_VERIFY_LIST',
    message:
      '비밀번호 재설정 유효시간이 초과되었습니다. 비밀번호 재설정 링크를 다시 발송해주세요.',
  },
  CANNOT_CHANGE_SAME_PASSWORD: {
    errorCode: 'CANNOT_CHANGE_SAME_PASSWORD',
    message: '기존 비밀번호와 다른 비밀번호를 입력해주세요.',
  },
  ALREADY_EXIST_RESTAURANT_PARTNER_COMPANY: {
    errorCode: 'ALREADY_EXIST_RESTAURANT_PARTNER_COMPANY',
    message: '이 매장에는 이미 해당 연동 업체와 매핑되어 있습니다.',
  },
  LIMIT_FILE_SIZE: {
    errorCode: 'LIMIT_FILE_SIZE',
    message: '업로드 용량은 파일당 최대 10MB까지 가능합니다.',
  },
  OFFLINE_NETWORK: {
    errorCode: 'OFFLINE_NETWORK',
    message: '일시적인 오류가 발생하였습니다.',
  },
  MISSING_MENU_MAPPINGS: {
    errorCode: 'MISSING_MENU_MAPPINGS',
    message: '메뉴를 1개 이상 매핑해주세요.',
  },
  MISSING_TABLE_MAPPINGS: {
    errorCode: 'MISSING_TABLE_MAPPINGS',
    message: '테이블을 1개 이상 매핑해주세요.',
  },
  NOT_FOUND_PARTNER_COMPANY_DATA: {
    errorCode: 'NOT_FOUND_PARTNER_COMPANY_DATA',
    message: '해당 매장의 마스터 정보를 찾을 수 없습니다.',
  },
  NEW_RESTAURANT_HAS_MENU_OPTION_GROUPS: {
    errorCode: 'NEW_RESTAURANT_HAS_MENU_OPTION_GROUPS',
    message: '복사할 매장의 메뉴 옵션이 비어있지 않습니다.',
  },
  NEW_RESTAURANT_HAS_MENUS: {
    errorCode: 'NEW_RESTAURANT_HAS_MENUS',
    message: '복사할 매장의 메뉴가 비어있지 않습니다.',
  },
  ORIGINAL_RESTAURANT_NO_MENUS: {
    errorCode: 'ORIGINAL_RESTAURANT_NO_MENUS',
    message: '원본 매장에 메뉴가 없습니다.',
  },
  ORIGINAL_RESTAURANT_IS_DELETED: {
    errorCode: 'ORIGINAL_RESTAURANT_IS_DELETED',
    message: '원본 매장이 삭제 상태입니다.',
  },
  CONFLICT_MENU_CATEGORY_NAME: {
    errorCode: 'CONFLICT_MENU_CATEGORY_NAME',
    message: '동일한 이름의 메뉴 카테고리가 이미 등록되어 있습니다.',
  },
  ALREADY_EXIST_MENU_NAME: {
    errorCode: 'ALREADY_EXIST_MENU_NAME',
    message: '동일 한 메뉴 이름이 존재합니다.',
  },
  ALREADY_CONNECT_ANOTHER_VAN_AGENT_ID: {
    errorCode: 'ALREADY_CONNECT_ANOTHER_VAN_AGENT_ID',
    message: '이미 다른 vanAgentId와 연결되어 있습니다.',
  },
  CANNOT_DELETE_CATEGORY_HAS_ACTIVE_MENU: {
    errorCode: 'CANNOT_DELETE_CATEGORY_HAS_ACTIVE_MENU',
    message: '해당 카테고리에 메뉴가 존재합니다.',
  },
  CANNOT_DELETE_MENU_HAS_ACTIVE_MENU: {
    errorCode: 'CANNOT_DELETE_MENU_HAS_ACTIVE_MENU',
    message: '활성화 된 메뉴가 있어서 삭제할 수 없습니다.',
  },
  NOT_EQUAL_MENU_CATEGORY_ID_LIST: {
    errorCode: 'NOT_EQUAL_MENU_CATEGORY_ID_LIST',
    message:
      '기존 카테고리 목록와 요청하신 카테고리 목록의 아이디가 일치하지 않습니다.',
  },
  NOT_EQUAL_MENU_CATEGORY_LIST: {
    errorCode: 'NOT_EQUAL_MENU_CATEGORY_LIST',
    message: '기존 카테고리 목록와 요청하신 카테고리 목록이 일치하지 않습니다.',
  },
  NOT_EQUAL_MENU_ID_LIST: {
    errorCode: 'NOT_EQUAL_MENU_ID_LIST',
    message:
      '기존 메뉴 목록와 요청하신 메뉴 목록의 아이디가 일치하지 않습니다.',
  },
  NOT_EQUAL_MENU_LIST: {
    errorCode: 'NOT_EQUAL_MENU_LIST',
    message: '기존 메뉴 목록와 요청하신 메뉴 목록이 일치하지 않습니다.',
  },
  ALREADY_LOGOUT_USER: {
    errorCode: 'ALREADY_LOGOUT_USER',
    message: '계정이 로그아웃 되었습니다. 다시 로그인해주세요.',
  },
  IS_HIDDEN_FIELD_CAN_ONLY_MODIFIED_MORE_THEN_ONE_CONTRACTS: {
    errorCode: 'IS_HIDDEN_FIELD_CAN_ONLY_MODIFIED_MORE_THEN_ONE_CONTRACTS',
    message: 'isHidden 필드는 계약 조건이 1개 이상일 때 수정할 수 있습니다.',
  },
  NO_USE_ON_SITE_ORDER_MENU: {
    errorCode: 'NO_USE_ON_SITE_ORDER_MENU',
    message: '해당 메뉴는 현장주문용 메뉴가 아닙니다.',
  },
  INVALID_PASSWORD_RULE: {
    errorCode: 'INVALID_PASSWORD_RULE',
    message: '영문, 숫자, 특수문자를 모두 포함하여 8자 이상 입력해 주세요.',
  },
  A125: {
    errorCode: 'A125',
    message: '사업자등록번호를 정확하게 입력해주세요.',
  },
  F130: {
    errorCode: 'F130',
    message: '사업자등록번호를 정확하게 입력해주세요.',
  },
  F160: {
    errorCode: 'F160',
    message: '사업자등록번호를 정확하게 입력해주세요.',
  },
  ALREADY_REGISTERED_NICEPAY_ID: {
    errorCode: 'ALREADY_REGISTERED_NICEPAY_ID',
    message: '이미 가입된 NICEPAY ID입니다.',
  },
  // FAILED_DELETE_USER: {
  //   errorCode: 'FAILED_DELETE_USER',
  //   message: '유저를 삭제할 수 없습니다.',
  // },
}

export function getErrorData(errorCode: string) {
  const item = errorData[errorCode]

  return item || errorData['UNKNOWN_ERROR']
}
